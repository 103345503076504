import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "./src/styles/global.css";

export const replaceComponentRenderer = ({ props }) => {
  const children = React.createElement(props.pageResources.component, props);

  return (
    <div>
      <TransitionGroup>
        <CSSTransition key={props["*"]} timeout={200} classNames="fade">
          <div
            style={{
              position: "absolute",
              width: "100%",
              backgroundColor: "#fff",
            }}
          >
            {children}
          </div>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};
